.mainContent {
    min-width: 600px;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 99999999;
}

.imgWrapper {
    text-align: center;
    background-color: white;
    color: blue;
    border: solid 5px blue;
    max-width: 800px;
    padding: 20px;
}

.contentBody {
    text-align: left;
}

.contentTitle {
    text-align: center;
}

.contentProject {
    font-weight: bold;
}

.linkToProject {
    text-align: left;
    color: blue;
    display: block;
}

.press {
    text-align: left;
}

.pressLink {
    color: blue;
    margin-left: 5px;
    margin-right: 5px;
}

@media only screen and (max-width: 1000px) {
    .mainContent {
        width: 100%;
        min-width: 100%;
        left: 0;
        top: 100px;
        transform: none;
    }

    .image {
        max-width: 330px;
    }

    .imgWrapper {
        text-align: center;
        max-width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .info {
        bottom: 260px;
    }
}