@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@800&display=swap');

.header {
    background-color: rgba(255,255,255,0); 
    position: fixed;
    color: white;
    font-size: 80px;
    font-weight: bold;
    text-decoration: underline;
    z-index: 999999;
}
.headerTop {
    top: 0;
}

.headerBtm {
    bottom: 0;
}

.about {
    background-color: red;
    height: 100px;
    width: 100%;
    position: fixed;
    top: -10px;
    transform: rotate(20deg);
    left: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
    vertical-align: middle;
}

.aboutText {
    right: 20px;
    text-decoration: underline;
    font-size: 4vw;
    top: 0;
}

.aboutText:hover {
    color: blue;
    font-style: italic;
}

.projectsText {
    left: 20px;
    font-size: 4vw;
    text-decoration: underline;
}

.projectsText:hover {
    color: blue;
    font-style: italic;
}

.nameText {
    left: 50%;
    font-size: 5vw;
    transform: translateX(-50%);
    font-family: 'Archivo', sans-serif;
    text-decoration: none;
}

.headerTopText {
    cursor: pointer;
    position: fixed;
    top: 0;
    color: white;
}

.contactText {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
}

.projects {
    /* background-color: #e46971; */
    /* background-color: #e86854; */
    /* background-color: #d03d43; */
    background-color: white;
    height: 100%;
    width: 300px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    vertical-align: middle;
    z-index: 99;
}

h1 span {
  font: 26px Monaco, MonoSpace;
  height: 200px;
  position: absolute;
  width: 20px;
  left: 700px;
  bottom: 10px;
  transform-origin: bottom center;
}

#lindso {
    animation: rotation 10s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.navigation {
    margin-left: 20px;
    margin-top: 20px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100px;
}

.header {
    background-color: black;
    border: none;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 50px;
    width: 600px;
}

.header-nav {
    color: white;
    display: inline;
}

.header-nav-list-item {
    color: white;
    display: inline;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    margin-left: 40px;
    margin-right: 40px;
}

.one {
    position: fixed;
    right: 200px;
}

.two {
    position: fixed;
    right: 40px;
}