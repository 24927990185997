.projectGridWrapper {
    position: absolute;
    top: 150px;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    justify-content: space-between;
}

.item {
  flex: 48%;
  max-width: 48%;
  padding: 0 4px;
}

/* .item:nth-child(3n) {
  width: 100%;
} */

.item img {
  margin-top: 40px;
  vertical-align: middle;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .item {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .item {
    flex: 100%;
    max-width: 100%;
  }
}

